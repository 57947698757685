@import "/src/styles/styles.scss";

.display-card {
  display: flex;
  flex-direction: column;
}

.banner-location {
  display: block;

  img {
    width: 100%;
    height: 415px;
    border-radius: 25px;
    object-fit: cover;
  }
}

.container-fiche {
  display: flex;
  flex-direction: column;
}

.fiche-sup {
  display: flex;

  h1 {
    font-weight: 500;
    color: $primary-color;
    padding-bottom: 10px;
  }

  h2 {
    font-weight: 500;
    color: $primary-color;
  }

  .tags {
    display: flex;
    flex-direction: row;

    ul {
      display: flex;
      flex-direction: row;
      align-items: center;
      list-style-type: none;
      margin: 0;
      padding: 0;
    }

    li {
      border-radius: 10px;
      margin-right: 20px;
      background-color: $primary-color;
      color: white;
      text-align: center;
    }
  }
}

.fiche-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.name-picture {
  width: 140px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 5px;

  p {
    white-space: pre-line;
    word-wrap: break-word;
    text-align: right;
    font-size: 18px;
    line-height: 25px;
    font-weight: 500;
    color: $primary-color;
    padding-right: 10px;
  }

  img {
    border-radius: 50%;
  }
}

.star {
  font-size: 16px;
  margin-right: 8px;
  display: inline-block;
}

.active-star {
  color: $primary-color;
}

.inactive-star {
  color: #e3e3e3;
}

////// Responsive
@media (min-width: 768px) {
  .fiche-sup {
    flex-direction: row;
    justify-content: space-between;
    margin-top: 40px;
    margin-bottom: 30px;

    h1 {
      font-size: 36px;
    }

    h2 {
      font-size: 18px;
    }

    .tags {
      margin-top: 30px;

      li {
        border-radius: 10px;
        margin-right: 20px;
        padding: 5px 20px 5px 20px;
        font-size: 14px;
      }
    }
  }

  .name-picture {
    img {
      width: 64px;
    }
  }
}

@media (min-width: 560px) and (max-width: 767px) {
  .fiche-sup {
    flex-direction: row;
    justify-content: space-between;
    margin-top: 40px;
    margin-bottom: 30px;

    h1 {
      font-size: 24px;
    }

    h2 {
      font-size: 18px;
    }

    .tags {
      margin-top: 30px;

      li {
        border-radius: 10px;
        margin-right: 20px;
        padding: 5px 20px 5px 20px;
        font-size: 14px;
      }
    }

    .name-picture {
      width: 130px;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;

      margin-top: 20px;

      p {
        white-space: pre-line;
        word-wrap: break-word;
        text-align: right;
        font-size: 15px;
        line-height: 20px;
        font-weight: 500;
        color: $primary-color;
        padding-right: 10px;
      }

      img {
        width: 48px;
      }
    }

    .star {
      font-size: 11px;
      margin-right: 4px;
      display: inline-block;
    }

    .active-star {
      color: $primary-color;
    }

    .inactive-star {
      color: #e3e3e3;
    }
  }
}

@media (max-width: 559px) {
  .display-card {
    padding: 0px 20px 0px 20px;
  }

  .banner-location {
    img {
      height: 255px;
      border-radius: 10px;
    }
  }

  .fiche-sup {
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 15px;

    h1 {
      font-size: 18px;
    }

    h2 {
      font-size: 14px;
    }

    .tags {
      margin-top: 15px;

      li {
        border-radius: 5px;
        margin-right: 15px;
        padding: 5px 10px 5px 10px;
        font-size: 10px;
      }
    }
  }

  .fiche-right {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;

    .name-picture {
      width: 100px;
      order: 2;

      p {
        font-size: 12px;
        line-height: 15px;
      }

      img {
        width: 36px;
      }
    }

    .star {
      font-size: 13px;
      margin-right: 5px;
    }
  }
}

// partie inferieure
// partie inferieure
// partie inferieure
// partie inferieure

@media (min-width: 560px) {
  .fiche-inf {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-top: 5px;
    min-height: 200px;
    gap: 10px;

    .specificities {
      display: block;
      width: 250px;
      width: 100%;
      margin-bottom: 25px;
    }
  }
}

////// Responsive
////// Responsive
////// Responsive

@media (max-width: 559px) {
  .fiche-inf {
    flex-direction: column;
    margin-bottom: 20px;
    min-height: 50px;

    .specificities {
      width: 100%;
      margin-bottom: 15px;
    }
  }
}
