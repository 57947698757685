@import "/src/styles/styles.scss";

/////* Bannière Home Page *//////
.banner {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  overflow: hidden;
  margin-bottom: 40px;
  background-color: black;

  img {
    position: absolute;
    left: 0;
    width: 100%;
    height: auto;
    opacity: 0.7;
  }

  h1 {
    position: absolute;
    color: #fff;
    font-weight: 500;
  }
}

////// Responsive
@media (min-width: 1025px) {
  .banner {
    height: 223px;
    border-radius: 25px;

    img {
      top: -300px;
    }

    h1 {
      font-size: 48px;
      text-align: center;
    }
  }
}

@media (min-width: 1025px) and (max-width: 1239px) {
  .container-banner {
  }
}

@media (min-width: 560px) and (max-width: 1024px) {
  .container-banner {
  }

  .banner {
    height: 140px;
    border-radius: 10px;

    img {
      top: -180px;
    }

    h1 {
      font-size: 30px;
      text-align: left;
      padding-left: 20px;

      span {
        display: inline-block;
      }
    }
  }
}

@media (max-width: 559px) {
  .container-banner {
  }

  .banner {
    height: 111px;
    border-radius: 10px;
    margin-bottom: 0px;

    img {
      top: -40px;
    }

    h1 {
      font-size: 24px;
      text-align: left;
      padding-left: 20px;

      span {
        display: inline-block;
      }
    }
  }
}

//////* Affichage des locations sur la home page *//////
.show-cards {
  display: grid;
  background-color: $bg-secondary;
  border-radius: 25px;
  grid-auto-rows: 340px;
}

.show-card {
  position: relative;
  width: 100%;
  height: 100%;

  img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    object-fit: cover;

    background: rgb(255, 96, 96);
    background: linear-gradient(
      180deg,
      rgba(255, 96, 96, 1) 0%,
      rgba(212, 73, 73, 1) 65%,
      rgba(143, 68, 68, 1) 100%
    );
  }

  &.no-image {
    display: none;
  }

  .title {
    display: block;
    position: absolute;
    z-index: 2;
    width: 100%;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: rgba(0, 0, 0, 0.3);
    bottom: 0;

    p {
      margin: 5px 10px 10px 10px;
      color: rgb(255, 255, 255);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

////// Responsive
@media (min-width: 1240px) {
  .show-cards {
    grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));
    gap: 50px;
    padding: 50px;
  }

  .title {
    height: 50px;

    p {
      font-size: 18px;
    }
  }
}

@media (min-width: 1024px) and (max-width: 1239px) {
  .show-cards {
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;
    padding: 40px;
    grid-auto-rows: 260px;
  }
  .title {
    height: 40px;

    p {
      font-size: 17px;
    }
  }
}

@media (min-width: 900px) and (max-width: 1023px) {
  .show-cards {
    grid-template-columns: repeat(3, 1fr);
    gap: 50px;
    padding: 30px;
    grid-auto-rows: 230px;
  }
  .title {
    height: 40px;

    p {
      font-size: 17px;
    }
  }
}

@media (min-width: 560px) and (max-width: 899px) {
  .show-cards {
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
    padding: 30px;
    grid-auto-rows: 230px;
  }

  .title {
    height: 35px;

    p {
      font-size: 16px;
    }
  }
}

@media (max-width: 559px) {
  .show-cards {
    background-color: transparent;
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
    padding-top: 20px;
    grid-auto-rows: 255px;
    overflow: hidden;
  }

  .show-card {
    flex-grow: 1;
    height: 100%;
    margin-bottom: 0;
  }
}
