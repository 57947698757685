@import "/src/styles/styles.scss";

.carousel-button {
  height: 100px;
  width: 100px;
  border: 0;
  text-align: center;
  color: #fff !important;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  font-size: 100px;
  cursor: pointer;

  &:hover,
  &:focus {
    background: transparent;
    color: #ffffff !important;
    outline: 0;
  }

  &.prev {
    position: absolute;
    left: 0px;
    top: 50%;
    transform: translateY(-50%);
  }

  &.next {
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translateY(-50%);
  }

  &.disabled:hover {
    cursor: not-allowed;
  }
}

.caroussel-container {
  position: relative;

  .react-slideshow-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .nav {
      z-index: 10;
      position: absolute;
      cursor: pointer;

      @media (min-width: 1024px) {
        &:first-of-type {
          left: 15px;
        }

        &:last-of-type {
          right: 15px;
        }
      }

      @media (min-width: 768px) and (max-width: 1023px) {
        &:first-of-type {
          left: 10px;
        }

        &:last-of-type {
          right: 10px;
        }
      }

      @media (max-width: 767px) {
        &:first-of-type {
          left: 5px;
        }

        &:last-of-type {
          right: 5px;
        }
      }
    }

    .default-nav {
      height: 50px;
      width: 50px;
      border: 0;
      text-align: center;
      color: #fff !important;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background: transparent;

      &:hover,
      &:focus {
        background: transparent;
        color: #ffffff !important;
        outline: 0;
      }

      &.disabled:hover {
        cursor: not-allowed;
      }

      &:first-of-type {
        left: 10px;
      }

      &:last-of-type {
        right: 10px;
      }
    }
  }

  .react-slideshow-fadezoom-wrapper {
    width: 100%;
    overflow: hidden;

    .react-slideshow-fadezoom-images-wrap {
      display: flex;
      flex-wrap: wrap;

      > div {
        position: relative;
        opacity: 0;
      }
    }
  }

  .react-slideshow-wrapper {
    &.slide {
      width: 100%;
      overflow: hidden;
    }

    .images-wrap.horizontal {
      display: flex;
      flex-wrap: wrap;

      > div[aria-hidden="true"] {
        display: none;
      }
    }
  }

  .each-slide-effect {
    overflow: hidden;
    position: relative;

    div {
      height: 100%;
      width: 100%;
      object-fit: cover;
      margin: auto;
      border-radius: 25px;
      position: relative;

      .slide-image {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: 25px;
        position: relative;
        transition: border-radius 0.5s ease;
      }
    }
  }

  .slide-number {
    display: block;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    color: rgb(255, 255, 255);
    font-size: 18px;
    transition: opacity 0.5s ease;
  }

  ///// Responsive
  @media (min-width: 1024px) {
    .each-slide-effect {
      height: 415px !important;
      border-radius: 25px;
    }
    .slide-number {
      bottom: 30px;
    }
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    .each-slide-effect {
      height: 375px !important;
      border-radius: 15px;
    }

    .slide-number {
      bottom: 30px;
    }
  }

  @media (max-width: 767px) {
    .carousel-button {
      height: 30px;
      width: 30px;
      font-size: 50px;
    }

    .each-slide-effect {
      height: 255px !important;
      border-radius: 10px;
    }

    .slide-number {
      display: none;
    }
  }
}
