//sass --watch about.scss:about.css fiche.scss:fiche.css footer.scss:footer.css header.scss:header.css home.scss:home.css layout.scss:layout.css styles.scss:styles.css accordion.scss:accordion.css slideshow.scss:slideshow.css
@import "styles.scss";

@import "../components/AboutKasa/styles/about.scss";
@import "../components/Card//styles/fiche.scss";
@import "../components/Footer/styles/footer.scss";
@import "../components/Header/styles/header.scss";
@import "../pages/Home/styles/home.scss";
@import "../components/Accordion/styles/accordion.scss";
@import "../components/Slideshow/styles/slideshow.scss";

html,
body {
  width: 100%;
  box-sizing: border-box;
  margin: 0;
  height: 100vh;
  background-color: white;
}

.main {
  margin-left: auto;
  margin-right: auto;
}

////// Responsive
@media (min-width: 1240px) {
  .main {
    width: 1240px;
  }
}

@media (max-width: 1239px) {
  .main {
    padding: 20px;
  }
}

////// Error 404

.error-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  h1 {
    color: $primary-color;
    font-weight: bold;
  }

  h2 {
    color: $primary-color;
    font-weight: 500;
  }

  a {
    color: $primary-color;
    font-weight: 500;
  }
}

@media (min-width: 1024px) {
  .error-page {
    min-height: 600px;

    h1 {
      font-size: 288px;
    }

    h2 {
      font-size: 36px;
    }

    a {
      font-size: 18px;
      padding-bottom: 80px;
    }
  }
}

@media (max-width: 1023px) {
  .error-page {
    min-height: 500px;

    h1 {
      font-size: 96px;
      padding-top: 60px;
    }

    h2 {
      font-size: 18px;
      width: 70%;

      span {
        display: inline-block;
      }
    }

    a {
      font-size: 14px;
      padding-bottom: 60px;
    }
  }
}
