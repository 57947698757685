@import "/src/styles/styles.scss";

.aboutus-specificities {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.aboutus-specificity {
  display: block;
  height: auto;
  margin-bottom: 25px;

  .about-title {
    display: block;
    height: 50px;
    background-color: $primary-color;
    border-radius: 5px;

    h2 {
      padding: 10px;
      padding-left: 20px;
      padding-right: 20px;
      margin: 0px;
      color: #fff;
      font-size: 24px;
      font-weight: 500;
    }
  }

  .about-describe {
    display: block;
    padding: 0px;
    margin: 0px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}

@media (max-width: 560px) {
  .aboutus-specificities {
    margin-top: 20px;
  }
}
