@import "/src/styles/styles.scss";

.footer {
  @include flex-center;
  background-color: black;
  position: relative;
  height: 220px;
  left: 0;
  bottom: 0;
  width: 100%;
  margin-bottom: 0px;

  color: white;
  text-align: center;
}

//Responsive
@media (min-width: 1240px) {
  .footer {
    img {
      width: 122px;
      padding-top: 60px;
    }

    p {
      font-size: 24px;
      padding-top: 40px;
      margin: 0px;
    }
  }
}

@media (max-width: 1239px) {
  .footer {
    padding-top: 60px;

    img {
      width: 122px;
    }

    p {
      font-size: 12px;
      margin: 0px;
      padding-top: 20px;
      width: 100px;
    }
  }
}
